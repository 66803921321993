import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import '../components/reglement.css'

const Busreglement = ({pageTitle}) => (
  <Layout>
    <SEO title="Busreglement" />
    <div className="reglement">
        <h2 className="reglement-title">Busreglement</h2>
        <ol>
            <li>Uitverplaatsingen met de Leuven Lads zullen via een facebookevenement en een mail naar alle leden worden aangekondigd. Van zodra de juiste informatie (vertrekuren/ticketprijzen) ter onze beschikking zijn, zullen we dit communiceren.</li>

            <li>Leuven Lads leden krijgen altijd de voorkeur tot inschrijving op de bus. De prijs voor een busverplaatsing bedraagt voor leden (ongeacht de leeftijd) steeds €10 en voor niet-leden €15.</li>
            <li>Inschrijving voor een uitverplaatsing gebeurt uitsluitend via een online inschrijvingsformulier (via een link op het facebookevent/in de verzonden mail) en dit om misverstanden te vermijden. Bij elke uitverplaatsing zal een inschrijvingsdeadline worden aangeduid, gelieve deze te respecteren.</li>
            <li>Wanneer door overmacht of ongekende reden (ongeval e.a.) de bus de verplaatsing niet kan maken kan de club niet verantwoordelijk gesteld worden. Ook voor gebeurlijke ongevallen tijdens de verplaatsingen is de club niet verantwoordelijk behalve deze waar de oorzaak duidelijk bij de inrichters ligt.</li>
            <li>Indien door ongekende of gekende reden (weersomstandigheden,…) de wedstrijd wordt afgelast en de bus reeds vertrokken was aan zijn vertrekplaats (busgarage) kan de eventuele nieuwe verplaatsing naar dezelfde tegenstrever niet gratis gebeuren. De leden betalen dan de vastgestelde ledenprijs en moeten zich opnieuw inschrijven.</li>
            <li>De club behoudt het recht om de busverplaatsingen te annuleren indien er minder dan 15 personen ingeschreven zijn. Men zal de leden tijdig verwittigen wanneer de bus niet meer wordt ingericht en reeds betaalde gelden zullen in dat geval worden teruggestort. We proberen dan de reeds ingeschreven leden mee te laten reizen met een andere bus.</li>
            <li>Op de bus worden drank en eventueel broodjes aan een democratische prijs verkocht. De prijs voor deze dranken en broodjes worden door het bestuur vastgelegd. De winst op de verkoop van de dranken en van de pronostiek zal dienen om de onkosten van de bus te betalen.</li>
            <li>De bus zal vertrekken aan op voorhand duidelijk gecommuniceerde vertrekplaatsen. We streven er naar om een vertrekplaats te voorzien in Rotselaar en in Leuven.</li>
            <li>De vertrekuren zullen op het facebookevenement en via mail worden meegedeeld. Gelieve deze stipt te respecteren. Eén kwartier na het eindsignaal zal de bus terug vertrekken naar onze opstapplaatsen.</li>
            <li>Het Bestuur Supportersclub Leuven Lads Gedragscode: Voor de gedragscode verwijzen we naar de officiële gedragscode die van toepassing is in de Belgische voetbalwereld.</li>
        </ol>
        <p><strong>Als supportersclub onthouden we ons van iedere vorm van geweld of hooliganisme en sluiten wij ons aan bij de geldende normen van alle Belgische clubs.</strong></p>
    </div>

  </Layout>
)

export default Busreglement
